import {
    getAnnualBenchmark,
    getAnnualMeasureMetricChartData,
    getMeasureMetricChartData,
    getQuarterlyBenchmark,
    getExportExcel,
    measureMetricGroupData,
} from "api/dashboardAPI";
import preloadCharts, {
    drawLineOrColumnChart,
    getAnnualChartDataToDisplay,
    getChartDataToDisplay,
    getChartGroups,
    getFilteredMeasureMetric,
    getMeasureMetricDashboardData,
} from "common/googleChartUtils";
import {
    getAllYears,
    getCurrentQuarter,
    getIntervalStartAndEndDate,
    exportFile,
    modifyDashBordTemplate,
    exportDashBoardPdf,
    localDateToYYYYMMDDFormatString,
    isChartVisible,
    getCanvas,
} from "common/utils";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CONSTANTS from "common/constants";
import { jsPDF as JsPdf } from "jspdf";
import html2canvas from "html2canvas";
import { getAssetDetails } from "api/certificateAPI";
import { showToast } from "components/Toast/toast.slice";
import axios from "axios";
import { useLocation } from "react-router";
import { DashboardChartsWrapper } from "./styled";
import GraphFilter from "../GraphFilters";
import GraphCards from "../GraphCards";

interface Props {
    programCategoryId: any;
    hospitalId: any;
    programId: any;
    programName: string;
    programData?: any;
    hospital?: any;
    certificateData?: any;
    dashboardType?: any;
}

export const DashboardCharts = ({
    programCategoryId,
    hospitalId,
    programId,
    programName,
    programData,
    hospital,
    certificateData,
    dashboardType,
}: Props) => {
    const dispatch = useDispatch();
    const currentYear = new Date().getFullYear();
    const currentQuarter = getCurrentQuarter();
    const [chartDataResponse, setChartDataResponse] = useState<any>();
    const [chartsDisable, setChartsDisable] = useState<boolean>(true);
    const [groupHeadings, setGroupHeadings] = useState<any>([]);
    const [chartGroups, setChartGroups] = useState<any>([]);
    const [selectedCharts, setSelectedCharts] = useState<any>([]);
    const [interval, setInterval] = useState<string>(
        CONSTANTS.INTERVAL.QUARTERLY
    );
    const [fromYear, setFromYear] = useState<number>(currentYear - 1);
    const [fromQuarter, setFromQuarter] = useState<number>(currentQuarter);
    const [toYear, setToYear] = useState<number>(
        currentQuarter == 1 ? currentYear - 1 : currentYear
    );
    const [toQuarter, setToQuarter] = useState<number>(
        currentQuarter == 1 ? currentQuarter + 3 : currentQuarter - 1
    );
    const [redrawInd, setRedrawInd] = useState<boolean>(false);
    const [mountedInd, setMountedInd] = useState<boolean>(false);
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
    const [benchmarkValues, setBenchmarkValues] = useState<any>();
    const [mesaureResponse, setMeasureRespone] = useState<any>();
    const [measurechartDetails, setMeasureChartDetails] = useState<any>();
    const [metricChartDetails, setMetricChartDetails] = useState<any>();
    const [metricResponse, setMetricResponse] = useState<any>();
    const [measureDropdownDetails, setMeasureDropdownDetails] = useState<any>(
        []
    );
    const [metricDropdownDetails, setMetricDropdownDetails] = useState<any>([]);
    const [tableRenderInd, setTableRenderInd] = useState<any>(false);
    const location = useLocation();
    const countryId = location?.state?.selectedCountryId
        ? location?.state?.selectedCountryId
        : localStorage.getItem("selectedHospitalCountryId");
    useEffect(() => {
        if (interval === CONSTANTS.INTERVAL.QUARTERLY) {
            const startDateAndEndDate = getIntervalStartAndEndDate(
                fromYear,
                fromQuarter,
                toYear,
                toQuarter
            );
            getDashboardChartData(startDateAndEndDate, mountedInd);
        } else {
            const allYears = getAllYears(fromYear, toYear);
            getAnnualDashboardChartData(allYears);
        }
    }, [redrawInd]);

    // Draw the chart views
    const displayCharts = (google: any, data: any, intervalName: string) => {
        const measureChartsData = data.measures;
        const metricChartsData = data.metrics;
        const measurechartList: any = [];
        const metricChartList: any = [];
        for (
            let measureChartIndex = 0;
            measureChartIndex <= measureChartsData.length - 1;
            measureChartIndex++
        ) {
            // get the chart data columns and rows
            const chartdata = getMeasureMetricDashboardData(
                google,
                measureChartsData[measureChartIndex],
                data.measureMetricTooltip,
                intervalName
            );
            const currentUrl = window.location.href;
            // create and draw the chart view
            const chartData = drawLineOrColumnChart(
                google,
                chartdata,
                `measureChart-${measureChartIndex}`,
                data.title,
                "",
                measureChartsData[measureChartIndex],
                programName,
                interval,
                dashboardType,
                currentUrl.includes("dashboard") ? 1135 : 982
            );
            measurechartList.push(chartData);
        }
        setMeasureChartDetails(measurechartList);
        for (
            let metricChartIndex = 0;
            metricChartIndex <= metricChartsData.length - 1;
            metricChartIndex++
        ) {
            // get the chart data columns and rows
            const chartdata = getMeasureMetricDashboardData(
                google,
                metricChartsData[metricChartIndex],
                data.measureMetricTooltip,
                intervalName
            );
            const currentUrl = window.location.href;
            // create and draw the chart view
            const metricChart = drawLineOrColumnChart(
                google,
                chartdata,
                `metricChart-${metricChartIndex}`,
                data.title,
                metricChartsData[metricChartIndex].metricType,
                metricChartsData[metricChartIndex],
                programName,
                interval,
                dashboardType,
                currentUrl.includes("dashboard") ? 1135 : 982
            );
            if (metricChart) {
                metricChartList.push(metricChart);
            }
        }
        setMetricChartDetails(metricChartList);
    };

    const getAnnualDashboardChartData = (allyears: any) => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        Promise.all([
            getAnnualMeasureMetricChartData(
                hospitalId,
                programCategoryId,
                allyears,
                countryId
            ),
            getAnnualBenchmark(programCategoryId, allyears, countryId),
        ]).then((responses: any) => {
            if (responses[0].success && responses[0].data) {
                setMeasureRespone({
                    from: fromYear,
                    to: toYear,
                    isAnnual: true,
                    measure: responses[0].data.measures,
                    benchmarkValues: responses[1]?.data?.measures,
                    measureMetricTooltip:
                        responses[0].data.measureMetricTooltip,
                });
                const { measures, metrics } = getFilteredMeasureMetric(
                    responses[0].data.measures,
                    responses[0].data.metric,
                    selectedCharts
                );
                const chartResponse = {
                    measures,
                    metrics,
                    measureMetricTooltip:
                        chartDataResponse.measureMetricTooltip,
                };
                setChartsDisable(false);
                const chartDataToDisplay = getAnnualChartDataToDisplay(
                    chartResponse,
                    fromYear,
                    toYear,
                    allyears
                );
                setChartDataResponse(chartDataToDisplay);
                setTableRenderInd(!tableRenderInd);
                const measuresdata = chartDataToDisplay?.measures?.map(
                    (measurechart: any) => ({
                        groupName: measurechart.groupName,
                        code: measurechart.codes,
                    })
                );
                const metricsdata = chartDataToDisplay?.metrics?.map(
                    (metricschart: any) => ({
                        groupName: metricschart.groupName,
                        code: metricschart.codes,
                    })
                );
                setMeasureDropdownDetails(measuresdata);
                setMetricDropdownDetails(metricsdata);
                preloadCharts(displayCharts, chartDataToDisplay, "Year");
                if (responses[1].success && responses[1].data) {
                    const annualBenchmarkDataToDisplay =
                        getAnnualChartDataToDisplay(
                            responses[1].data,
                            fromYear,
                            toYear,
                            allyears,
                            true
                        );
                    setBenchmarkValues(annualBenchmarkDataToDisplay);
                }
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                setSubmitDisabled(true);
            }
        });
    };

    const exportData = () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        const startDateAndEndDate = getIntervalStartAndEndDate(
            fromYear,
            fromQuarter,
            toYear,
            toQuarter
        );
        const allYears = getAllYears(fromYear, toYear);
        let measureMetricChartDropDownCode: any[] = [];
        measureDropdownDetails.map((data: any) => {
            measureMetricChartDropDownCode = [
                ...measureMetricChartDropDownCode,
                ...data.code,
            ];
        });
        if (interval === CONSTANTS.INTERVAL.QUARTERLY) {
            metricDropdownDetails
                .filter((data: any) => data.code)
                .map((data: any) => {
                    measureMetricChartDropDownCode = [
                        ...measureMetricChartDropDownCode,
                        ...data.code,
                    ];
                });
        }
        const measureMetricDropDown = {
            measureMetricDropDownData: [...measureMetricChartDropDownCode],
        };
        getExportExcel(
            hospitalId,
            programId,
            programCategoryId,
            startDateAndEndDate?.split("/")[0],
            startDateAndEndDate?.split("/")[1],
            interval,
            measureMetricDropDown,
            allYears,
            countryId
        )
            .then((response) => {
                exportFile(response.data.data, programData.programCategoryName);
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                const toast = {
                    message:
                        "Organization Dashboard successfully exported as Excel.",
                    code: "Success:",
                    type: "success",
                };
                dispatch(showToast(toast));
            })
            .catch(() => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    };
    const getDashboardChartData = (
        startDateAndEndDate: string,
        mountedInd: boolean
    ) => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        Promise.all([
            getMeasureMetricChartData(
                hospitalId,
                programId,
                programCategoryId,
                startDateAndEndDate?.split("/")[0],
                startDateAndEndDate?.split("/")[1],
                "Quarterly",
                countryId
            ),
            getQuarterlyBenchmark(
                programCategoryId,
                startDateAndEndDate?.split("/")[0],
                startDateAndEndDate?.split("/")[1],
                "Quarterly",
                countryId
            ),
        ]).then(async (responses: any) => {
            if (responses[0].success && responses[0].data) {
                setMeasureRespone({
                    fromYear,
                    toYear,
                    fromQuarter,
                    toQuarter,
                    benchmarkValues: responses[1]?.data?.measures,
                    measure: responses[0].data.measures,
                    measureMetricTooltip:
                        responses[0].data.measureMetricTooltip,
                });
                setMetricResponse({
                    fromYear,
                    toYear,
                    fromQuarter,
                    toQuarter,
                    metric: responses[0].data.metrics,
                    measureMetricTooltip:
                        responses[0].data.measureMetricTooltip,
                });
                let chartResponse;
                if (!mountedInd) {
                    const measureMetricGroupResult: any =
                        await measureMetricGroupData(
                            getAllYears(2019, currentYear),
                            countryId,
                            programCategoryId
                        );
                    const { headings, measureMetricList } = getChartGroups(
                        measureMetricGroupResult.data.measures,
                        measureMetricGroupResult.data.metrics
                    );
                    setSelectedCharts([...measureMetricList]);
                    setChartGroups(measureMetricList);
                    setGroupHeadings([...headings]);
                    const { measures, metrics } = getFilteredMeasureMetric(
                        responses[0].data.measures,
                        responses[0].data.metrics,
                        [...measureMetricList]
                    );
                    chartResponse = {
                        measures,
                        metrics,
                        measureMetricTooltip:
                            responses[0].data.measureMetricTooltip,
                    };
                } else {
                    const { measures, metrics } = getFilteredMeasureMetric(
                        responses[0].data.measures,
                        responses[0].data.metrics,
                        selectedCharts
                    );
                    chartResponse = {
                        measures,
                        metrics,
                        measureMetricTooltip:
                            responses[0].data.measureMetricTooltip,
                    };
                }
                setChartsDisable(false);
                const chartDataToDisplay = getChartDataToDisplay(
                    chartResponse,
                    fromYear,
                    fromQuarter,
                    toYear,
                    toQuarter
                );
                setChartDataResponse(chartDataToDisplay);
                const measuresdata = chartDataToDisplay?.measures?.map(
                    (measurechart: any) => ({
                        groupName: measurechart.groupName,
                        code: measurechart.codes,
                    })
                );
                const metricsdata = chartDataToDisplay?.metrics?.map(
                    (metricschart: any) => ({
                        groupName: metricschart.groupName,
                        code: metricschart.codes,
                    })
                );
                setMeasureDropdownDetails(measuresdata);
                setMetricDropdownDetails(metricsdata);
                preloadCharts(displayCharts, chartDataToDisplay, "Quarter");
                setTableRenderInd(!tableRenderInd);
                if (responses[1].success && responses[1].data) {
                    const benchmarkDataToDisplay = getChartDataToDisplay(
                        responses[1].data,
                        fromYear,
                        fromQuarter,
                        toYear,
                        toQuarter,
                        true
                    );
                    setBenchmarkValues(benchmarkDataToDisplay);
                }
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                setSubmitDisabled(true);
                setMountedInd(true);
            }
        });
    };

    async function exportDashBoardDetails() {
        const pdfDocument = new JsPdf("p", "mm", "a4", true);
        const imgWidth = 200;
        const pagePaddingLeft = 5;
        const imageType = "JPEG";
        let nextPosition = 10;
        let footerCanvas: any;
        const hiddenFrame: any =
            document.getElementsByClassName("hiddenFrame")[0];
        const hiddenFrameDoc = hiddenFrame.contentWindow.document;
        const imageInfo = {
            imgWidth: 200,
            pageHeight: 300,
            pagePaddingLeft: 5,
            imageType: "JPEG",
        };
        const currentUrl = window.location.href;
        modifyDashBordTemplate(
            hiddenFrameDoc,
            hospital,
            programData,
            certificateData
        );
        /* footer canvas creation */
        await html2canvas(hiddenFrameDoc.querySelector("footer"), {
            scale: 2,
        }).then((canvas: any) => {
            footerCanvas = canvas;
        });
        /* function to set footer on each new page at bottom */
        async function setFooter() {
            const footerCanvasImage = footerCanvas.toDataURL("image/jpeg");
            const footerImgHeight =
                (footerCanvas.height * imgWidth) / footerCanvas.width;
            pdfDocument.addImage(
                footerCanvasImage,
                imageType,
                pagePaddingLeft,
                282,
                imgWidth,
                footerImgHeight,
                "",
                "FAST"
            );
        }
        setFooter();
        const capturedImages = [];
        /* canvas creation of header & appending header and footer canvases into pdf */
        capturedImages.push(
            getCanvas("printElementHeader", hiddenFrameDoc, -3)
        );
        capturedImages.push(getCanvas("hospitalBanner", hiddenFrameDoc, 3));
        const measureChartVisibleInd = isChartVisible(measurechartDetails);
        if (measurechartDetails.length > 0 && !measureChartVisibleInd) {
            capturedImages.push(
                getCanvas("templateHeading", hiddenFrameDoc, 0)
            );
            nextPosition = await exportDashBoardPdf(
                nextPosition,
                hiddenFrameDoc,
                measurechartDetails,
                mesaureResponse,
                true,
                capturedImages,
                currentUrl.includes("dashboard")
            );
        }
        const metricChartVisibleInd = isChartVisible(metricChartDetails);
        if (
            metricChartDetails.length > 0 &&
            !metricChartVisibleInd &&
            !mesaureResponse.isAnnual
        ) {
            if (nextPosition + 120 > imageInfo.pageHeight) {
                pdfDocument.addPage();
                setFooter();
                nextPosition = 5;
            } else {
                nextPosition += 10;
            }
            capturedImages.push(
                getCanvas("templateHeadingMetric", hiddenFrameDoc, 2)
            );
            await exportDashBoardPdf(
                nextPosition,
                hiddenFrameDoc,
                metricChartDetails,
                metricResponse,
                false,
                capturedImages,
                currentUrl.includes("dashboard")
            );
        }
        const imagePromises = await Promise.all(capturedImages);
        for (let index = 0; index <= imagePromises.length - 1; index++) {
            const canvasElement = await imagePromises[index].canvasElement;
            const imgData = canvasElement.toDataURL("image/jpeg");
            const imgHeight =
                (canvasElement.height * imageInfo.imgWidth) /
                canvasElement.width;
            nextPosition += imagePromises[index].distance;

            if (nextPosition + imgHeight + 20 > imageInfo.pageHeight) {
                pdfDocument.addPage();
                nextPosition = 5;
                pdfDocument.addImage(
                    imgData,
                    imageInfo.imageType,
                    imageInfo.pagePaddingLeft,
                    nextPosition,
                    imageInfo.imgWidth,
                    imgHeight,
                    "",
                    "FAST"
                );
                if (setFooter) {
                    setFooter();
                }
                nextPosition =
                    nextPosition + imgHeight + imagePromises[index].distance;
            } else {
                pdfDocument.addImage(
                    imgData,
                    imageInfo.imageType,
                    imageInfo.pagePaddingLeft,
                    nextPosition,
                    imageInfo.imgWidth,
                    imgHeight,
                    "",
                    "FAST"
                );
                nextPosition =
                    nextPosition + imgHeight + imagePromises[index].distance;
            }
        }
        const disclaimerCanvasElement = await html2canvas(
            hiddenFrameDoc.getElementById("disclaimer"),
            {
                scale: 2,
            }
        );
        const disclaimerImgData =
            disclaimerCanvasElement.toDataURL("image/jpeg");
        const disclaimerImgHeight =
            (disclaimerCanvasElement.height * imageInfo.imgWidth) /
            disclaimerCanvasElement.width;
        if (nextPosition + disclaimerImgHeight + 20 > imageInfo.pageHeight) {
            pdfDocument.addPage();
            setFooter();
            nextPosition = 5;
        }
        pdfDocument.addImage(
            disclaimerImgData,
            imageInfo.imageType,
            imageInfo.pagePaddingLeft,
            nextPosition + 5,
            imageInfo.imgWidth,
            disclaimerImgHeight,
            "",
            "FAST"
        );
        /* remove iframe which appended to the main document */
        if (hiddenFrame && hiddenFrame.parentNode) {
            hiddenFrame.parentNode.removeChild(hiddenFrame);
        }
        /* generate PDF file and download */
        pdfDocument.save(
            `AHA-${
                programData.programCategoryName
            }-Dashboard Data-${localDateToYYYYMMDDFormatString(new Date())}.pdf`
        );
    }
    const exportDashBoardAsPdf = () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        getAssetDetails(CONSTANTS.EXPORT_DASHBOARD_TEMPLATE, true).then(
            async (presignedURL: any) => {
                if (
                    presignedURL.data?.assets?.[0].isDownloadable &&
                    presignedURL.data?.assets?.[0].presignedUrl
                ) {
                    const iframe: any = document.createElement("iframe");
                    iframe.setAttribute("height", "0");
                    iframe.setAttribute("width", "0");
                    iframe.className = "hiddenFrame";
                    try {
                        const axiosResponse = await axios({
                            method: "get",
                            url: presignedURL.data?.assets?.[0].presignedUrl,
                        });
                        if (axiosResponse.data) {
                            const templateHtml = axiosResponse.data;
                            document.body.appendChild(iframe);
                            iframe.onload = async () => {
                                await exportDashBoardDetails();
                                const toast = {
                                    message:
                                        "Organization Dashboard successfully exported as PDF.",
                                    code: "Success:",
                                    type: "success",
                                };
                                dispatch(showToast(toast));
                                dispatch(
                                    setPageLoadingStatus({
                                        isPageLoading: false,
                                    })
                                );
                            };
                            iframe.contentWindow.document.open();
                            iframe.contentWindow.document.write(templateHtml);
                            iframe.contentWindow.document.close();
                        }
                    } catch {
                        const toast = {
                            message: "Export template doesn't exist.",
                        };

                        dispatch(showToast(toast));
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                    }
                }
            }
        );
    };

    return (
        <DashboardChartsWrapper className="db-chart">
            <GraphFilter
                chartGroups={chartGroups}
                selectedCharts={selectedCharts}
                setSelectedCharts={setSelectedCharts}
                groupHeadings={groupHeadings}
                chartsDisable={chartsDisable}
                setFromYear={setFromYear}
                setFromQuarter={setFromQuarter}
                setToYear={setToYear}
                setToQuarter={setToQuarter}
                setRedrawInd={setRedrawInd}
                redrawInd={redrawInd}
                setInterval={setInterval}
                submitDisabled={submitDisabled}
                setSubmitDisabled={setSubmitDisabled}
                exportData={exportData}
                exportDashBoardAsPdf={exportDashBoardAsPdf}
                metricChartDetails={metricChartDetails}
                measurechartDetails={measurechartDetails}
            />
            <div role="group" aria-labelledby="measures-table">
                {chartDataResponse?.measures &&
                    chartDataResponse.measures.length > 0 && (
                        <h2 className="h4 mb-1" id="measures-table">
                            Measures
                        </h2>
                    )}
                {benchmarkValues?.measures &&
                    chartDataResponse?.measures?.map(
                        (measurechart: any, index: number) => {
                            const benchmarkDetail =
                                benchmarkValues.measures.filter(
                                    (measure: any) =>
                                        measurechart.groupName ===
                                        measure.groupName
                                );
                            return (
                                <div
                                    key={`${measurechart.measureName}-${index}`}
                                >
                                    <GraphCards
                                        chartId={`measureChart-${index}`}
                                        filterItems={measurechart.filterItems}
                                        measureMetricChart={measurechart}
                                        title={chartDataResponse.title}
                                        tooltips={
                                            chartDataResponse.measureMetricTooltip
                                        }
                                        interval={interval}
                                        setMeasureDropdownDetails={
                                            setMeasureDropdownDetails
                                        }
                                        measureDropdownDetails={
                                            measureDropdownDetails
                                        }
                                        programName={programName}
                                        benchmarkDetails={benchmarkDetail[0]}
                                        benchmarkValue={
                                            benchmarkDetail[0].benchmarkValue
                                        }
                                        measureMetricChartDetails={
                                            measurechartDetails
                                        }
                                        setMeasureMetricChartDetails={
                                            setMeasureChartDetails
                                        }
                                        dashboardType={dashboardType}
                                        tableRenderInd={tableRenderInd}
                                    />
                                </div>
                            );
                        }
                    )}
            </div>
            <div role="group" aria-labelledby="metrics-table">
                {chartDataResponse?.metrics &&
                    chartDataResponse?.metrics?.length > 0 && (
                        <h2 className="h4 mb-1" id="metrics-table">
                            Metrics
                        </h2>
                    )}
                {chartDataResponse?.metrics?.map(
                    (metricchart: any, index: number) => (
                        <div key={`${metricchart.metricName}-${index}`}>
                            <GraphCards
                                chartId={`metricChart-${index}`}
                                filterItems={metricchart.filterItems}
                                measureMetricChart={metricchart}
                                title={chartDataResponse.title}
                                tooltips={
                                    chartDataResponse.measureMetricTooltip
                                }
                                interval={interval}
                                metricDropdownDetails={metricDropdownDetails}
                                setMetricDropdownDetails={
                                    setMetricDropdownDetails
                                }
                                programName={programName}
                                measureMetricChartDetails={metricChartDetails}
                                setMeasureMetricChartDetails={
                                    setMetricChartDetails
                                }
                                dashboardType={dashboardType}
                                tableRenderInd={tableRenderInd}
                            />
                        </div>
                    )
                )}
            </div>
        </DashboardChartsWrapper>
    );
};

export default DashboardCharts;
