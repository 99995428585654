import React, { useEffect, useRef, useState } from "react";
import { HospitalManagementDetailsWrapper } from "./styled";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import "../../styles/styles.scss";
import HospitalMeasuresTab from "pages/Admin/HospitalMeasuresTab/HospitalMeasuresTab";
import HospitalDocumentsTab from "pages/Admin/HospitalDocumentsTab/HospitalDocumentsTab";
import HospitalCertificationTab from "pages/Admin/HospitalCertificationTab/HospitalCertificationTab";
import { useDispatch, useSelector } from "react-redux";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { getHospitalProgramDetails } from "api/hospitalProgramsAPI";
import { getHospitalById } from "api/hospitalApi";
import { getHospitalProgramMeasureMetrics } from "api/measuresApi";
import {
    getCurrentQuarter,
    getQuarterStartAndEndDate,
    showModal,
    dismissModal,
} from "common/utils";
import CONSTANTS from "common/constants";
import { BreadCrumb } from "components/BreadCrumb";
import {
    setQuarter,
    setYear,
} from "components/MeasuresDateFilter/measuresDateFilter.slice";
import MoreActions from "components/MoreActions/MoreActions";
import FocusTrap from "focus-trap-react";
import { deactivateHospital } from "api/adminDashboardAPI";
import { hideToast, showToast } from "components/Toast/toast.slice";
import { getArchivalAccordionDatesAPI } from "api/archivalDocumentIntegrationAPI";
import Tab from "components/Tab";
import store from "app/store";
import webWorkerScript from "../../../common/webWorker";
import HospitalDashboard from "../HospitalDashboard/HospitalDashboard";
import Sidebar from "../Sidebar/Sidebar";
import { logger } from "../../../utils/logger.utils";
import config from "../../../config";
import FacilityDetails from "../FacilityDetails/FacilityDetails";
import { getHospitalProgramCertificate } from "../../../api/certificateAPI";
import DocumentServiceAPI from "../../../api/documentsAPI";

export interface IMeasure {
    measureId: number;
    measureName: string;
    description: string;
    numerator: number;
    denominator: number;
}

export const RegisteredHospitalDetails = () => {
    // REDUX variables
    const dispatch = useDispatch();
    const location = useLocation();
    const measuresDateFilter = useSelector((state: any) => {
        return state.measuresDateFilter;
    });
    // ROUTE VARIABLES
    const { hospitalId, programId, categoryId }: any = useParams();
    const registryId = location.state?.registryId
        ? location.state?.registryId
        : null;
    const registryName = location.state?.registryName
        ? location.state?.registryName
        : null;
    const facilityName = location.state?.facilityName
        ? location.state?.facilityName
        : null;
    const corporationId = location.state?.corporationId
        ? location.state?.corporationId
        : null;
    const corporationName = location.state?.corporationName
        ? location.state?.corporationName
        : null;
    const pageNumber = location.state?.hRP ? location.state?.hRP : null;
    const mangangementTab = location.state?.bT
        ? location.state?.bT
        : CONSTANTS.ACTIVE;
    const mangangementPage = location.state?.bP ? location.state?.bP : 1;

    // RFC STATE
    const [hospital, setHospital] = useState<any>({});
    const [programName, setProgramName] = useState<any>();
    const [programData, setProgramData] = useState<any>();
    const [certificateData, setCertificateData] = useState<any>();
    const [returnPath, setReturnPath] = useState<any>(null);
    const [isMeasureTableLoading, setIsMeasureTableLoading] =
        useState<boolean>(false);
    const documentsTabRef = useRef<any>(null);
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);
    const [hospitalMeasureMetrics, setHospitalMeasureMetrics] = useState<any>();
    const [measuresInd, setMeasuresInd] = useState<boolean>(false);
    const [documentsInd, setDocumentsInd] = useState<boolean>(false);
    const [certificateInd, setCertificateInd] = useState<boolean>(false);
    const [detailsInd, setDetailsInd] = useState<boolean>(false);
    const [hideMeasure, setHideMeasure] = useState<boolean>(false);
    const [deactivateFacilityActivateInd, setDeactivateFacilityActivateInd] =
        useState<boolean>(false);
    const childRef = useRef();
    const [archivaedDate, setArchivaedDate] = useState<any>();
    const [documentCount, setDocumentCount] = useState<Number>();
    const restrictDeactivation = localStorage.getItem("restictDeactivation");
    const [deactivationInProgress, setDeactivationInProgress] =
        useState<boolean>(() => restrictDeactivation === "true");
    const [docTabInd, setDocTabInd] = useState<boolean>(false);
    const [hideCertificates, setHideCertificates] = useState<boolean>(true);
    const documentServiceAPI = new DocumentServiceAPI();
    const history = useHistory();
    const [selectedTab, setSelectedTab] = useState<any>(0);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const deactivateOrganizationRef = useRef<HTMLDivElement | null>(null);
    const countryId = localStorage.setItem(
        "selectedHospitalCountryId",
        hospital.countryId
    );
    const handleSelectedTab = (value: any) => {
        setSelectedTab(value);
    };

    useEffect(() => {
        getFacilityDetails();
        const elementsArray: any = [];
        let pathToReturn: any;
        if (registryId) {
            pathToReturn = {
                registryId,
                pathname: `/registry/registry-management/registry-details/${registryId}`,
                state: {
                    hRP: pageNumber,
                    p: pageNumber,
                    bT: mangangementTab,
                    bP: mangangementPage,
                    registryName,
                },
            };
            setReturnPath(pathToReturn);
            elementsArray.push(
                {
                    label: "Registry Management",
                    returnPath: {
                        pathname: "/registry/registry-management",
                        state: {
                            t: mangangementTab,
                            p: mangangementPage,
                        },
                    },
                },
                {
                    label: registryName,
                    returnPath: {
                        pathname: `/registry/registry-management/registry-details/${registryId}`,
                        state: {
                            bT: mangangementTab,
                            bP: mangangementPage,
                            registryName,
                        },
                    },
                },
                {
                    label: "Organizations Registered",
                    returnPath: pathToReturn,
                },
                {
                    label: facilityName,
                    returnPath: pathToReturn,
                }
            );
            setBreadcrumbItems(elementsArray);
        }
        if (corporationId) {
            pathToReturn = {
                corporationId,
                pathname: `/corporation/corporation-management/corporation-details/${corporationId}`,
                state: {
                    hRP: pageNumber,
                    p: pageNumber,
                    bT: mangangementTab,
                    bP: mangangementPage,
                    corporationName,
                    activeTab: location?.state?.activeTab,
                },
            };
            setReturnPath(pathToReturn);
            elementsArray.push(
                {
                    label: "Corporation Management",
                    returnPath: {
                        pathname: "/corporation/corporation-management",
                        state: {
                            t: mangangementTab,
                            p: mangangementPage,
                        },
                    },
                },
                {
                    label: corporationName,
                    returnPath: {
                        pathname: `/corporation/corporation-management/corporation-details/${corporationId}`,
                        state: {
                            bT: mangangementTab,
                            bP: mangangementPage,
                            corporationName,
                        },
                    },
                },
                {
                    label: "Organizations Registered",
                    returnPath: pathToReturn,
                },
                {
                    label: facilityName,
                    returnPath: pathToReturn,
                }
            );
            setBreadcrumbItems(elementsArray);
        }
        if (location?.state?.backToFaciclityManagement) {
            pathToReturn = {
                pathname: location?.state?.pathname
                    ? location?.state?.pathname
                    : location?.state?.backToFaciclityManagement,
                state: {
                    adminfacilityMangemnetTab:
                        location?.state?.adminfacilityMangemnetTab,
                    adminfacilityMangemnetPage:
                        location?.state?.adminfacilityMangemnetPage,
                    pageSort: location?.state?.pageSort,
                    countryId,
                    facilityName,
                },
            };
            setReturnPath(pathToReturn);
            elementsArray.push(
                {
                    label: "Organization Management",
                    returnPath: pathToReturn,
                },
                {
                    label:
                        location?.state?.adminfacilityMangemnetTab ==
                        CONSTANTS.CERTIFIED
                            ? "Certified Organization"
                            : location?.state?.adminfacilityMangemnetTab ==
                              CONSTANTS.RENEWING
                            ? "Renewing Organization"
                            : "Registered Organization",
                    returnPath: pathToReturn,
                },
                {
                    label: facilityName,
                    returnPath: pathToReturn,
                }
            );
            setBreadcrumbItems(elementsArray);
        }
        if (location?.state?.backToAdminDashboard) {
            pathToReturn = { pathname: location?.state?.backToAdminDashboard };
            setReturnPath(pathToReturn);
            elementsArray.push(
                {
                    label: "Dashboard",
                    returnPath: pathToReturn,
                },
                {
                    label: "Registration Details",
                    returnPath: pathToReturn,
                }
            );
            setBreadcrumbItems(elementsArray);
        }
    }, []);
    useEffect(() => {
        if (programId == 16) {
            setHideMeasure(true);
        }
        getArchivalAccordionDatesAPI(hospitalId).then((res: any) => {
            if (res.statusCode == 200) {
                const archivalDate = res.data.archiveDates;
                setArchivaedDate(
                    res.data.archiveDates[archivalDate.length - 1]
                );
            }
        });
    }, []);

    const getFacilityDetails = () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        const startDateAndEndDate = getQuarterStartAndEndDate(
            measuresDateFilter.year,
            measuresDateFilter.quarter
        );
        if (startDateAndEndDate) {
            Promise.all([
                getHospitalById(hospitalId),
                getHospitalProgramDetails(programId, hospitalId),
                getHospitalProgramCertificate(
                    hospitalId,
                    programId,
                    categoryId
                ),
            ])
                .then((response: any) => {
                    if (programId == 16) {
                        setHideMeasure(true);
                    }
                    if (
                        response[0].success &&
                        response[1].success &&
                        response[2].success
                    ) {
                        const { countryCode } = response[0].data.hospital;
                        setHideCertificates(
                            CONSTANTS.countriesToHideCertificates.includes(
                                countryCode
                            ) && Number(programId) === 13
                        );
                        setHospital(response[0].data.hospital);
                        localStorage.setItem(
                            "selectedHospitalCountryCode",
                            response[0].data.hospital.countryCode
                        );
                        setProgramName(response[1].data.programCategoryName);
                        setProgramData(response[1].data);
                        setCertificateData(response[2].data.programCertficate);
                        setDetailsInd(false);
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                    }
                })
                .catch(() => {
                    setDetailsInd(false);
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                });
        }
    };

    useEffect(() => {
        if (detailsInd) {
            getFacilityDetails();
        }
    }, [detailsInd]);

    useEffect(() => {
        (async () => {
            setIsMeasureTableLoading(true);
            const startDateAndEndDate = getQuarterStartAndEndDate(
                measuresDateFilter.year,
                measuresDateFilter.quarter
            );
            if (startDateAndEndDate) {
                setHospitalMeasureMetrics({
                    ...hospitalMeasureMetrics,
                    measuresData: [],
                    metricsData: [],
                });
                Promise.all([
                    getHospitalProgramMeasureMetrics(
                        hospitalId,
                        programId,
                        categoryId,
                        startDateAndEndDate?.split("/")[0],
                        startDateAndEndDate?.split("/")[1]
                    ),
                ])
                    .then((response: any) => {
                        setIsMeasureTableLoading(false);
                        if (response[0].statusCode == 200) {
                            setHospitalMeasureMetrics(response[0].data);
                        }
                    })
                    .catch((err) => {
                        logger(err);
                    });
            }
        })();
    }, [measuresDateFilter]);

    useEffect(() => {
        if (measuresInd) {
            dispatch(setPageLoadingStatus({ isPageLoading: true }));
            setIsMeasureTableLoading(true);
            dispatch(setYear(new Date().getFullYear()));
            dispatch(setQuarter(getCurrentQuarter()));
            const startDateAndEndDate = getQuarterStartAndEndDate(
                new Date().getFullYear(),
                getCurrentQuarter()
            );
            if (startDateAndEndDate) {
                setHospitalMeasureMetrics({
                    ...hospitalMeasureMetrics,
                    measuresData: [],
                    metricsData: [],
                });
                Promise.all([
                    getHospitalById(hospitalId),
                    getHospitalProgramMeasureMetrics(
                        hospitalId,
                        programId,
                        categoryId,
                        startDateAndEndDate?.split("/")[0],
                        startDateAndEndDate?.split("/")[1]
                    ),
                ])
                    .then((response: any) => {
                        setIsMeasureTableLoading(false);
                        setMeasuresInd(false);
                        if (response[0].success && response[1].success) {
                            setHospital(response[0].data.hospital);
                            setHospitalMeasureMetrics(response[1].data);
                        }
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                    })
                    .catch(() => {
                        setMeasuresInd(false);
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                    });
            }
        }
    }, [measuresInd]);

    useEffect(() => {
        if (documentsInd || certificateInd) {
            dispatch(setPageLoadingStatus({ isPageLoading: true }));
            Promise.all([getHospitalById(hospitalId)])
                .then((response: any) => {
                    setDocumentsInd(false);
                    setCertificateInd(false);
                    if (response[0].success && response[1].success) {
                        setHospital(response[0].data.hospital);
                    }
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                })
                .catch(() => {
                    setDocumentsInd(false);
                    setCertificateInd(false);
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                });
        }
    }, [documentsInd, certificateInd]);

    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Tooltip) {
            CONSTANTS.WINDOW.AUI.Tooltip.init();
        }
    });
    const exportFunctionInDeactivate = () => {
        if (childRef.current) {
            const childRefCurrent = childRef.current as {
                exportFacilityData: () => void;
            };
            childRefCurrent.exportFacilityData();
        }
    };
    const getDocumentCountAndPendingStatus = () => {
        return new Promise<void>((resolve, reject) => {
            dispatch(setPageLoadingStatus({ isPageLoading: true }));
            documentServiceAPI
                .getDocumentCount(hospital.hospitalId, programId, categoryId)
                .then((response: any) => {
                    if (!response.data.documentsPendingForApproval) {
                        setDocumentCount(response.data.documentsCount);
                        setDeactivateFacilityActivateInd(true);
                        resolve();
                    } else {
                        const toast = {
                            message:
                                "Please approve or reject the document in pending status to deactivate the organization",
                        };
                        dispatch(showToast(toast));
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                        reject();
                    }
                })
                .catch((error: any) => {
                    logger(error);
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                    reject();
                });
        });
    };
    const handleRenewalThread = (URL: any) => {
        const worker = new Worker(webWorkerScript);
        const WAIT_TIME = 5000;
        setDeactivationInProgress(true);
        worker.addEventListener("message", (e: any) => {
            const type = e?.data?.type;
            const status = e?.data?.status;
            if (type === "apiResponse") {
                if (status === CONSTANTS.RENEW_CONFIRM_RESPONSE.IN_PROGRESS) {
                    localStorage.setItem("restictDeactivation", "true");
                    worker.postMessage({
                        type: "start",
                        authToken: localStorage.getItem("userAccessToken"),
                        URL,
                        time: WAIT_TIME,
                    });
                } else if (
                    status === CONSTANTS.RENEW_CONFIRM_RESPONSE.SUCCESS
                ) {
                    store.dispatch(hideToast(false));
                    localStorage.setItem("restictDeactivation", "false");
                    setDeactivationInProgress(false);
                    const toast = {
                        message: `${hospital.facilityName} deactivation successfully completed.`,
                        code: "Success:",
                        type: "success",
                    };
                    dispatch(showToast(toast));
                } else if (status === CONSTANTS.RENEW_CONFIRM_RESPONSE.FAILED) {
                    store.dispatch(hideToast(false));
                    localStorage.setItem("restictDeactivation", "false");
                    setDeactivationInProgress(false);
                    const alertPopup = document.getElementById("alertPopup");
                    const msg = document.getElementById(
                        "faild-message"
                    ) as HTMLElement;
                    if (msg) {
                        msg.innerHTML = "";
                    }
                    document
                        .getElementById("faild-message")
                        ?.append(
                            `${hospital.facilityName} deactivation failed. Please try again`
                        );
                    alertPopup?.classList.remove("popupHide");
                }
            }
        });
        const toast = {
            message: `${hospital.facilityName} deactivation is in progress. Please don't refresh this page`,
            code: "Success:",
            type: "warning",
        };
        dispatch(showToast(toast));
        worker.postMessage({
            type: "start",
            authToken: localStorage.getItem("userAccessToken"),
            URL,
            time: WAIT_TIME,
        });
    };
    const sortDropdown = [
        {
            label: CONSTANTS.EXPORT,
            value: CONSTANTS.EXPORT,
            className: "aha-icon-export menu-cst-icon",
        },
        {
            label: CONSTANTS.DEACTIVATE,
            value: CONSTANTS.DEACTIVATE,
            className: "aha-icon-stop font-red mr-2",
        },
        {
            label: CONSTANTS.ARCHIVED,
            value: CONSTANTS.ARCHIVED,
            className: "aha-icon-archived font-red mr-2",
        },
        {
            label: CONSTANTS.USER_MANAGEMENT,
            value: CONSTANTS.USER_MANAGEMENT,
            className: "aha-icon-users-group  font-red mr-2",
        },
    ];
    const callParentOnSelect = (value: any) => {
        if (value === CONSTANTS.EXPORT) {
            return exportFunctionInDeactivate();
        }
        if (value === CONSTANTS.DEACTIVATE) {
            return deactivateFacilityConfirmationPopupModal();
        }
        if (value === CONSTANTS.ARCHIVED) {
            history.push(
                `/admin/organization-management/archived-view/${hospitalId}/program/${programId}/category/${categoryId}`,
                {
                    customState: returnPath,
                    isArchived: true,
                    facilityName: hospital.facilityName,
                    pathname: "/admin/organization-management",
                    search: location?.state?.search,
                    registryId,
                    corporationId,
                    countryId,
                }
            );
        }
        if (value === CONSTANTS.USER_MANAGEMENT) {
            history.push(
                `/admin/organization-management/organization-user-management`,
                {
                    pathname:
                        "/admin/organization-management/organization-user-management",
                    search: location?.state?.search,
                    adminfacilityMangemnetTab:
                        location.state?.adminfacilityMangemnetTab ||
                        hospital.approvalStatus,
                    hospitalId: hospital.hospitalId,
                    programId,
                    facilityName: hospital.facilityName,
                    countryId,
                    backToOrganizationManagement:
                        "/admin/organization-management",
                    backReturn: `/admin/organization-management/organization-details/${hospitalId}/program/${programId}/category/${categoryId}`,
                }
            );
        }
    };
    const deactivateFacilityConfirmationPopupModal = () => {
        if (hospital.facilityCode) {
            getDocumentCountAndPendingStatus()
                .then(() => {
                    showModal();
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                })
                .catch(() => {
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                });
        }
    };
    const deactivateFacilityConfirmationDismissModal = () => {
        setDeactivateFacilityActivateInd(false);
        dismissModal();
    };
    const deactivateFacility = async () => {
        if (deactivationInProgress) {
            dispatch(
                showToast({
                    message:
                        "Please wait for the current organization deactivation to complete.",
                })
            );
            return;
        }
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        deactivateHospital(
            hospital.facilityCode,
            false,
            hospital.hospitalId,
            hospital.approvalStatus,
            parseInt(categoryId, 10)
        )
            .then((response) => {
                if (response.success) {
                    close();
                    deactivateFacilityConfirmationDismissModal();
                    const URL = `/admin/renewFacilities/hospitals/${hospital.hospitalId}/${response.data.processId}`;
                    handleRenewalThread(URL);
                }
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                history.push(`/admin/organization-management`, {
                    customState: returnPath,
                    pathname: "/admin/organization-management",
                    adminfacilityMangemnetTab:
                        location?.state?.adminfacilityMangemnetTab,
                    search: location?.state?.search,
                    deactivateInd: true,
                    hospitalInd: hospital?.hospitalId,
                });
            })
            .catch((error) => {
                logger(error);
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    };

    const navigateToDoc = () => {
        deactivateFacilityConfirmationDismissModal();
        setDocTabInd(true);
    };

    useEffect(() => {
        if (docTabInd) {
            const button = document.querySelector(
                'button[role="tab"][aria-controls="documentstab"]'
            ) as HTMLButtonElement;
            button?.click();
            setDocTabInd(false);
        }
    }, [docTabInd]);

    const organizationTabsOnContent = [
        {
            id: "dashboardtab",
            title: "Dashboard",
            onClick: () => {
                setDetailsInd(false);
            },
            content: (
                <div
                    className="pt-3 justify-content-start program-list flex-column"
                    id="orgDashboard"
                >
                    <div className="tabContent">
                        <div>
                            <HospitalDashboard
                                hospital={hospital}
                                returnPath={returnPath}
                                programData={programData}
                                certificateData={certificateData}
                                updateHospitalInfo={(
                                    updatedHospitalInfo: any
                                ) => {
                                    setHospital(updatedHospitalInfo);
                                }}
                            />
                        </div>
                    </div>
                </div>
            ),
        },
        {
            id: "detailstab",
            title: "Details",
            onClick: () => {
                setDetailsInd(true);
            },
            content: (
                <div
                    className="pt-3 justify-content-start program-list flex-column"
                    id="orgDetails"
                >
                    <div className="tabContent">
                        <div>
                            <FacilityDetails
                                hospital={hospital}
                                returnPath={returnPath}
                                programData={programData}
                                certificateData={certificateData}
                                updateHospitalInfo={(
                                    updatedHospitalInfo: any
                                ) => {
                                    setHospital(updatedHospitalInfo);
                                }}
                                detailsInd={detailsInd}
                                ref={childRef}
                            />
                        </div>
                    </div>
                </div>
            ),
        },
        ...(!hideMeasure
            ? [
                  {
                      id: "measurestab",
                      title: "Measures",
                      onClick: () => {
                          setMeasuresInd(true);
                      },
                      content: (
                          <div
                              className="pt-3 justify-content-start program-list flex-column"
                              id="orgMeasures"
                          >
                              <div className="tabContent">
                                  <div>
                                      <HospitalMeasuresTab
                                          hospital={hospital}
                                          programName={programName}
                                          hospitalMeasures={
                                              hospitalMeasureMetrics
                                          }
                                          isMeasureTableLoading={
                                              isMeasureTableLoading
                                          }
                                      />
                                  </div>
                              </div>
                          </div>
                      ),
                  },
              ]
            : []),
        {
            id: "documentstab",
            title: "Documents",
            onClick: () => {
                setDocumentsInd(true);
                if (documentsTabRef.current) {
                    documentsTabRef.current.resetPageNumber(); // Reset to page 1
                }
            },
            content: (
                <div
                    className="pt-3 justify-content-start program-list flex-column"
                    id="orgDocuments"
                >
                    <div className="tabContent">
                        <div>
                            <HospitalDocumentsTab
                                hospital={hospital}
                                programName={programName}
                                documentsInd={documentsInd}
                                ref={documentsTabRef}
                            />
                        </div>
                    </div>
                </div>
            ),
        },
        ...(config &&
        config[config.env]?.featureConfig?.certification === "ON" &&
        !hideCertificates &&
        programId !== CONSTANTS.HFC_PROGRAM_ID
            ? [
                  {
                      id: "certificationtab",
                      title: "Certificate",
                      content: (
                          <div
                              className="pt-3 justify-content-start program-list flex-column"
                              id="orgCertificates"
                          >
                              <div className="tabContent">
                                  <div>
                                      <HospitalCertificationTab
                                          hospital={hospital}
                                          programName={programName}
                                      />
                                  </div>
                              </div>
                          </div>
                      ),
                  },
              ]
            : []),
    ].filter(Boolean);

    useEffect(() => {
        if (
            deactivateFacilityActivateInd &&
            deactivateOrganizationRef.current
        ) {
            deactivateOrganizationRef.current.setAttribute("tabIndex", "-1");
            deactivateOrganizationRef.current.focus();
        }
        const handleKeyDown = (event: any) => {
            if (event.key === "Escape") {
                deactivateFacilityConfirmationDismissModal();
            }
        };
        const handleMouseDown = (event: any) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                deactivateFacilityConfirmationDismissModal();
            }
            if (modalRef.current && modalRef.current.contains(event.target)) {
                deactivateOrganizationRef.current?.removeAttribute("tabIndex");
            }
        };

        if (deactivateFacilityActivateInd) {
            document.addEventListener("keydown", handleKeyDown);
            document.addEventListener("mousedown", handleMouseDown);
        }

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, [deactivateFacilityActivateInd]);

    return (
        <HospitalManagementDetailsWrapper className="container-fluid p-0 d-flex flex-grow-1">
            <div className="row no-gutters w-100 flex-grow-1">
                <Sidebar activeTab="hospitalManagement" />

                <section className="d-flex admin-main-content container select-text">
                    <div className="row no-gutters w-100 admin-main">
                        <BreadCrumb breadcrumbItems={breadcrumbItems} />
                        <div className="col-12 navbar-expand-lg">
                            <div className="hospital-mgnt-header d-flex justify-content-between align-items-center mb-4">
                                <h1
                                    className="h2 mb-0 font-400"
                                    id="orgManagement"
                                >
                                    Organization Management
                                </h1>
                                <div className="d-flex">
                                    <div
                                        id="moreactions"
                                        className="d-flex align-items-center more-actions"
                                    >
                                        <div
                                            className="export-dropdown ml-2"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="More Actions"
                                            data-container="#moreactions"
                                        >
                                            <MoreActions
                                                sortDropdown={sortDropdown}
                                                callParentOnSelect={
                                                    callParentOnSelect
                                                }
                                            />
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        className="navbar-toggler ml-3"
                                        data-toggle="collapse"
                                        data-target="#navbarManagement"
                                        aria-controls="navbarManagement"
                                        aria-expanded="false"
                                        aria-label="navigation Menu"
                                    >
                                        <i
                                            className="aha-icon-hamburger"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </div>
                            {(hospital.approvalStatus == CONSTANTS.REGISTRED ||
                                hospital.approvalStatus == CONSTANTS.RENEWING ||
                                hospital.approvalStatus == CONSTANTS.AWARDED ||
                                hospital.approvalStatus ==
                                    CONSTANTS.CERTIFIED) &&
                                hospital.isActive == true &&
                                deactivateFacilityActivateInd && (
                                    <FocusTrap
                                        focusTrapOptions={{
                                            escapeDeactivates: false,
                                            clickOutsideDeactivates: false,
                                        }}
                                    >
                                        <div
                                            className="modal fade show aui-modal deactivate-modal d-block"
                                            tabIndex={-1}
                                            aria-labelledby="detailsConfirmDeactiveModal"
                                            aria-modal="true"
                                            role="dialog"
                                        >
                                            <div
                                                className="modal-dialog modal-dialog-centered modal-md"
                                                ref={modalRef}
                                            >
                                                <div
                                                    className="modal-content"
                                                    id="deactivateOrganizationRef"
                                                    ref={
                                                        deactivateOrganizationRef
                                                    }
                                                >
                                                    <div className="modal-body p-0">
                                                        <div className="modal-header">
                                                            <h2
                                                                className="h5 font-600"
                                                                id="detailsConfirmDeactiveModal"
                                                            >
                                                                Confirm
                                                                Deactivate
                                                                Organization
                                                            </h2>
                                                            <button
                                                                id="closeExportModal"
                                                                type="button"
                                                                className="close"
                                                                aria-label="Close deactivating organization modal"
                                                                onClick={
                                                                    deactivateFacilityConfirmationDismissModal
                                                                }
                                                            >
                                                                <span
                                                                    aria-hidden="true"
                                                                    className="aha-icon-cross"
                                                                />
                                                            </button>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="pb-3">
                                                                <p>
                                                                    Are you sure
                                                                    you want to
                                                                    proceed with
                                                                    deactivating&nbsp;
                                                                    <b>
                                                                        {
                                                                            hospital.facilityName
                                                                        }
                                                                    </b>{" "}
                                                                    and
                                                                    archiving
                                                                    all the
                                                                    existing
                                                                    <Link
                                                                        to={`/admin/organization-management/organization-details/${hospital.hospitalId}/program/${programId}/category/${categoryId}`}
                                                                        className="font-red mx-1"
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            textDecoration:
                                                                                "underline",
                                                                        }}
                                                                        onClick={() => {
                                                                            navigateToDoc();
                                                                            if (
                                                                                documentsTabRef.current
                                                                            ) {
                                                                                documentsTabRef.current.resetPageNumber(); // Reset to page 1
                                                                            }
                                                                        }}
                                                                        aria-label={`${documentCount} documents of ${hospital.facilityName}`}
                                                                    >
                                                                        {
                                                                            documentCount
                                                                        }{" "}
                                                                        document(s)
                                                                    </Link>
                                                                    and
                                                                    certificates?
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="mt-4 d-flex justify-content-end">
                                                            <button
                                                                className="btn btn-secondary btn-round btn-md mx-4"
                                                                aria-label="Cancel Deactivation and close the modal"
                                                                onClick={
                                                                    deactivateFacilityConfirmationDismissModal
                                                                }
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                className="btn btn-primary btn-round mt-4  mt-sm-0"
                                                                onClick={
                                                                    deactivateFacility
                                                                }
                                                                data-dismiss="modal"
                                                                aria-label="Confirm Deactivate"
                                                            >
                                                                Confirm
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </FocusTrap>
                                )}
                            <div className="org-mgnt-tab">
                                <Tab
                                    handleSelectedTab={handleSelectedTab}
                                    tabTitle={organizationTabsOnContent}
                                    tabGroup="orgMgmt"
                                    tabLabel="orgManagement"
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </HospitalManagementDetailsWrapper>
    );
};

export default RegisteredHospitalDetails;
